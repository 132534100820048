import { useState } from "react";
import http from "../http";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import store from "@/store";
import { useTranslation } from "react-i18next";
import "@/assets/sass/login.scss"
export default function Login() {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  let [username, setUsername] = useState("");
  let [password, setPassword] = useState("");
  let [remember, setRemember] = useState(false);
  const navigate = useNavigate();
  let login = (event) => {
    event && event.preventDefault();
    let emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailReg.test(username)) {
      messageApi.open({
        type: "error",
        content: t("login.input_email"),
      });
      return;
    }
    let passwordReg = /^(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
    if (!passwordReg.test(password)) {
      messageApi.open({
        type: "error",
        content: t("login.input_password"),
      });
      return;
    }
    http
      .post("/login", {
        username,
        password,
      })
      .then((res) => {
        if (remember) {
          localStorage.setItem("token", res.token);
          sessionStorage.removeItem("token");
        } else {
          localStorage.removeItem("token");
          sessionStorage.setItem("token", res.token);
        }

        store.dispatch({ type: "changeUserInfo", userInfo: res.user });
        store.dispatch({ type: "changeIsLogin", isLogin: true });
        navigate("/");
        setTimeout(window.reset, 20);
      })
      .catch(() => { });
  };
  let notOpen = () => messageApi.error(t('login.not_open_yet'))
  return (
    <div className="login">
      {/* End Header Section */}
      {contextHolder}
      <div className="cs-height_90 cs-height_lg_80"></div>
      <div className="box">
        <div className="left">
        </div>
        <div className="right">

          <form className="cs-form_card" onSubmit={(e) => e.preventDefault()}>
            <h2 className="cs-form_title">
              {t("login.login")}
            </h2>
            <p className="cs-form_tip">{t('login.access_account')}</p>
            <div className="cs-form_card_in">
              <div className="cs-form_label">{t('login.email_label')}</div>
              <div className="cs-form_field_wrap">
                <input type="text" value={username} onChange={(event) => setUsername(event.target.value)} className="cs-form_field" placeholder={t("login.email")} />
              </div>
              <div className="cs-height_20 cs-height_lg_20"></div>
              <div className="cs-form_label">{t('login.password_label')}</div>
              <div className="cs-form_field_wrap">
                <input type="password" value={password} onChange={(event) => setPassword(event.target.value)} className="cs-form_field" placeholder={t("login.password")} />
              </div>
              <div className="cs-height_20 cs-height_lg_20"></div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  value={remember}
                  onChange={(event) => setRemember(event.target.checked)}
                  type="checkbox"
                  id="notify"
                />
                <div className="d-flex justify-content-between">

                  <label className="form-check-label" htmlFor="notify">
                    {t("login.remember")}
                  </label>
                  <div className="pointer" onClick={() => navigate('/retrieve')}>{t('login.forgot_password')}</div>
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
              <button
                className="cs-btn cs-style1 cs-btn_lg w-100"
                onClick={login}
              >
                <span>{t("login.sign")}</span>
              </button>
              <div className="cs-height_15 cs-height_lg_15"></div>
              {/* <button className="cs-btn google-login cs-style1 cs-btn_lg w-100" onClick={notOpen}>
                <span>
                  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2390_16042)">
                      <path d="M30.668 16.3104C30.668 15.1593 30.5727 14.3193 30.3664 13.4482H16.9537V18.6437H24.8267C24.668 19.9349 23.8109 21.8793 21.9061 23.1859L21.8794 23.3599L26.1202 26.5795L26.414 26.6082C29.1124 24.166 30.668 20.5726 30.668 16.3104Z" fill="#4285F4" />
                      <path d="M16.952 29.9998C20.809 29.9998 24.0471 28.7553 26.4122 26.6086L21.9043 23.1863C20.698 24.0108 19.0789 24.5863 16.952 24.5863C13.1742 24.5863 9.9679 22.1441 8.82494 18.7686L8.65741 18.7825L4.24773 22.127L4.19006 22.2841C6.53924 26.8574 11.3646 29.9998 16.952 29.9998Z" fill="#34A853" />
                      <path d="M8.82724 18.769C8.52566 17.8979 8.35112 16.9645 8.35112 16.0001C8.35112 15.0356 8.52566 14.1023 8.81137 13.2312L8.80339 13.0456L4.33844 9.64746L4.19235 9.71556C3.22414 11.6134 2.66858 13.7445 2.66858 16.0001C2.66858 18.2556 3.22414 20.3867 4.19235 22.2845L8.82724 18.769Z" fill="#FBBC05" />
                      <path d="M16.952 7.4133C19.6345 7.4133 21.444 8.54885 22.4758 9.4978L26.5075 5.64C24.0314 3.38445 20.8091 2 16.952 2C11.3647 2 6.53925 5.1422 4.19006 9.71549L8.8091 13.2311C9.96794 9.85555 13.1743 7.4133 16.952 7.4133Z" fill="#EB4335" />
                    </g>
                    <defs>
                      <clipPath id="clip0_2390_16042">
                        <rect width="32" height="32" fill="white" transform="translate(0.666626)" />
                      </clipPath>
                    </defs>
                  </svg>
                  &nbsp;
                  {t('login.use_google_login')}
                </span>
              </button> */}
              <div className="cs-height_30 cs-height_lg_30"></div>
              <div className="cs-form_footer text-center">
                {t("login.no_account")}{" "}&nbsp;
                <a href="#/register" className="text-primary">{t("login.register_now")}</a>
                &nbsp;
                {t("login.no_account1")}{" "}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
