import "./App.css";
import "./App.scss";
import NavHeader from "./components/nav-header";
import Footer from "./components/Footer";
import RouterView from "./router";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Provider, connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
function App(props) {
  const Location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    setIsMobile(
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      ) || window.innerWidth <= 768
    );
  };
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    setIsMobile(
      /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      ) || window.innerWidth <= 768
    );
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.reset && window.reset();
  }, [Location]);
  return (
    <>
      <div className="cs-preloader cs-center">
        <div className="cs-preloader_in"></div>
        <span>Loading</span>
      </div>
      {isMobile && (
        <div className="home-dialogs">
          <div className="dialog-contents">
            <img src="/assets/img/waining-bg.png" alt="" />
            <div className="dialog-title">Notification</div>
            <br/>
            <div className="dialog-desc">
            현재 해당 서비스는 모바일에서 이용이 어려우며, <br/>
            개발 중에 있습니다. 번거로우시겠지만, <br/>
            PC를 통해 방문해 주시기 바랍니다.
            </div>
            <br/>
            <div className="dialog-desc">
            'Best Patent' service is currently not available on mobile devices as it is still under development. We recommend accessing it via PC for now. We apologize for the inconvenience.
            </div>
            <a className="cs-btn cs-style1">
              <span>닫기</span>
            </a>
          </div>
        </div>
      )}
      <div id={Location.pathname}></div>
      <Spin
        style={{ position: "fixed" }}
        spinning={props.isLoading}
        indicator={<LoadingOutlined spin />}
        tip="loading..."
        size="large"
      >
        <div className="App">
          <NavHeader />
          <RouterView />
          <Footer />
        </div>
      </Spin>
    </>
  );
}

export default connect((state) => {
  return {
    isLoading: state.isLoading,
  };
})(App);
